import { API_URL } from "../../constants";
import axiosInstance from "./api";
import { User } from "../../models/user";
import { v4 as uuidv4 } from "uuid";

export { useUserActions };

function useUserActions() {
  const createUser = async (user) => {
    const response = await axiosInstance.post(`${API_URL}/users/register`, {
      username: user.username,
      email: user.email,
      password: user.password,
      first_name: user.firstName,
      last_name: user.lastName,
      date_of_birth: user.dateOfBirth,
      gender: user.gender,
      avatar: user.avatar,
      phone: user.phone,
    });
    return response.data;
  };

  const retrieveUser = async (userId) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/users/${userId}`);
      const user = new User({
        id: response.data.id,
        username: response.data.username,
        email: response.data.email,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        dateOfBirth: response.data.date_of_birth,
        gender: response.data.gender,
        avatar: response.data.avatar,
        phone: response.data.phone,
      });
      return user;
    } catch (error) {
      console.error(`Error retrieving user with id ${userId}`, error);
      throw error;
    }
  };

  const updateUser = async (user) => {
    console.log(`updating user with ${JSON.stringify(user, null, 2)}`);
    const response = await axiosInstance.put(`${API_URL}/users/${user.id}`, {
      username: user.username,
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      date_of_birth: user.dateOfBirth,
      gender: user.gender,
      avatar: user.avatar,
      phone: user.phone,
    });
    return response.data;
  };

  const requestPasswordReset = async (email) => {
    console.log(`requestPasswordReset: ${email}`);
    const response = await axiosInstance.post(
      `${API_URL}/users/password_reset_request`,
      {
        email: email,
      }
    );
    return response.data;
  };

  const resetPassword = async (password, code) => {
    const response = await axiosInstance.post(
      `${API_URL}/users/password_reset`,
      {
        password: password,
        code: code,
      }
    );
    return response.data;
  };

  const login = async (username, password) => {
    const response = await axiosInstance.post(`${API_URL}/users/login`, {
      username,
      password,
    });

    if (response.data.token) {
      localStorage.setItem(
        "loggedInUser",
        JSON.stringify({
          userId: response.data.id,
          token: response.data.token,
          analyticsId: uuidv4(),
        })
      );
    }
    return response.data;
  };

  const logout = () => {
    console.log("logging out");
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("selectedTeam");
    localStorage.removeItem("selectedPerson");
    localStorage.removeItem("ladder");
    localStorage.removeItem("activeTab");
    localStorage.removeItem("logBatch");

    // Don't remove theme and themeColor from localStorage perhaps?
    // localStorage.removeItem("theme");
    // localStorage.removeItem("themeColor");
  };

  const join = async (inviteCode) => {
    const data = new FormData();
    data.append("invite_code", inviteCode);
    const response = await axiosInstance.post(`${API_URL}/users/join`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  };

  const getLoggedInUser = () => {
    return JSON.parse(localStorage.getItem("loggedInUser"));
  };

  return {
    createUser,
    retrieveUser,
    updateUser,
    requestPasswordReset,
    resetPassword,
    login,
    join,
    logout,
    getLoggedInUser,
  };
}
