import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUsers } from "../../hooks/use-users";
import { useUserActions } from "../../services/api/user-service";
import { User } from "../../models/user";
import styles from "./profile.module.css";

const Profile = () => {
  const userActions = useUserActions();

  const userId = userActions.getLoggedInUser().userId;
  const { isUserLoading, userError, user, updateUser } = useUsers({ userId });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    if (user) {
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
    }
  }, [user, setValue]);

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const onSubmit = (e) => {
    // update user
    updateUser.mutate(
      new User({
        id: userId,
        firstName: firstName,
        lastName: lastName,
      })
    );
  };

  if (isUserLoading) {
    return <div>Loading...</div>;
  }

  if (userError) {
    return <div>Error: {userError.message}</div>;
  }

  return (
    <div className={styles.container}>
      <form className={styles.profileForm} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="first-name">First Name</label>
        <input
          type="text"
          defaultValue={user.firstName}
          id="first-name"
          placeholder="First Name"
          {...register("firstName", { required: false })}
        />

        <label htmlFor="last-name">Last Name</label>
        <input
          type="text"
          defaultValue={user.lastName}
          id="last-name"
          placeholder="Last Name"
          {...register("lastName", { required: false })}
        />
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default Profile;
